import React, { Component } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import {
  type Elements,
  type InputConversion,
  type OutputConversion,
  type Query,
} from "../components/ContextProvider";
import {
  credentialsAll,
  getDataColumnValue,
  getInputTypeForSourceType,
  getOutputTypesForInputType,
  getPrettySourceName,
} from "../components/DataSources";
import {
  QuestionHelper,
  QuestionKindSelect,
  type Question,
  type QuestionKind,
} from "../components/OpenAi";
import {
  ProductInfoArea,
  getDefaultRequest as getDefaultIceCatRequest,
  type Request as IceCatRequest,
} from "../components/IceCat";
import Select, {
  type GroupBase,
  type Props,
  type Theme,
  components,
} from "react-select";
import { Icon } from "./Icons";
import {
  GoogleSearchArea,
  getDefaultSearchRequest as getDefaultGoogleSearchRequest,
  type SearchRequest as GoogleSearchRequest,
} from "../components/Google";

export const NOT_SET = "not-set";

function sortElementNames(names: string[]): string[] {
  return names.slice(0).sort((a, b) => a.localeCompare(b));
}

export interface Option {
  value: string;
  label: string;
}

export type Language = "cz" | "sk" | "en" | "hu";
export type LanguageTitle = "Czech" | "Slovak" | "English" | "Hungarian";

export interface LanguageOption {
  value: Language;
  label: string;
}

interface ElementSelectProps {
  inputId?: string;
  value: string | null;
  onChange: (value: string | null) => void;
  loading: boolean;
  elements: Elements;
  isDisabled?: boolean;
}

interface LanguageSelectProps {
  inputId?: string;
  value: LanguageOption["value"];
  onChange: (value: LanguageOption["value"] | null) => void;
  disabled?: boolean;
}

interface DataSourceSelectProps {
  className?: string;
  value: InputConversion["type"] | null;
  onChange: (value: InputConversion["type"] | null) => void;
  loading: boolean;
  sources: string[];
}

interface QuerySelectProps {
  value: number | null;
  onChange: (value: number | null) => void;
  loading: boolean;
  queries: Query[];
  disabled: boolean;
}

interface InputElementSelectProps {
  className?: string;
  onChange: (inputArgs: InputConversion["args"] | null) => void;
  onChangeOutputType: (outputType: OutputConversion["type"]) => void;
  value: InputConversion["args"] | null;
  loading: boolean;
  elements: Elements;
  inputType: InputConversion["type"] | null;
  disabled: boolean;
}

interface DataValueSelectProps {
  className?: string;
  value: OutputConversion["type"] | null;
  onChange: (value: OutputConversion["type"] | null) => void;
  source: InputConversion["type"] | null;
  disabled: boolean;
  inputElement?: string;
  outputTypes?: string[];
}

interface OutputElementSelectProps {
  t: (text: string) => string;
  className?: string;
  onChange: (name: OutputConversion["args"] | null) => void;
  value: OutputConversion["args"] | null;
  defaultValue: string | null;
  loading: boolean;
  elements: Elements;
  disabled: boolean;
}

interface OutputElementSelectState {
  useDefault: boolean;
}

interface LabelProps {
  htmlFor: string;
  text: string;
}

interface OpenAiQuestionAreaProps {
  onChange: (question: Question) => void;
  onChangeOutputType: (outputType: OutputConversion["type"]) => void;
  question: Question;
  elements: Elements;
}

const selectTheme = (theme: Theme) => ({
  ...theme,
  borderRadius: 3,
  colors: {
    ...theme.colors,
    primary25: "#e0eef0",
    primary50: "#99c7cd",
    primary75: "#56a2ac",
    primary: "#0e7b89",
  },
});

export function getOptionValue<T>(option: Option | null) {
  if (option) {
    return option.value as T;
  }
  return null;
}

export function PrettySelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: Props<Option, IsMulti, Group>) {
  const { t } = useTranslation();
  return (
    <Select
      className="react-select"
      placeholder={t("element.select.prompt.label")}
      theme={selectTheme}
      {...props}
    />
  );
}

export function getLanguageTitle(language: Language): LanguageTitle {
  switch (language) {
    case "cz":
      return "Czech";
    case "sk":
      return "Slovak";
    case "en":
      return "English";
    case "hu":
      return "Hungarian";
  }
}

export function getLanguageFromTitle(title: LanguageTitle): Language {
  switch (title) {
    case "Czech":
      return "cz";
    case "Slovak":
      return "sk";
    case "English":
      return "en";
    case "Hungarian":
      return "hu";
  }
}

export function Label({ htmlFor, text }: LabelProps) {
  const { t } = useTranslation();
  return <label htmlFor={htmlFor}>{t(text)}</label>;
}

export function getElementsOptions(
  elements: Elements,
  parents: string[] = [],
): Option[] {
  const result: Option[] = [];

  for (const name of sortElementNames(Object.keys(elements))) {
    const element = elements[name];
    const fullName =
      parents.length > 0 ? `${parents.join(" | ")} | ${name}` : name;

    result.push({
      value: fullName,
      label: fullName,
    });

    if (element.children) {
      result.push(...getElementsOptions(element.children, [...parents, name]));
    }
  }

  return result;
}

export function LanguageSelect({
  inputId,
  value,
  onChange,
  disabled,
}: LanguageSelectProps) {
  const { t } = useTranslation();
  const defaultOption: LanguageOption = {
    value: "cz",
    label: t("element.prompt.rewriteSelect.languageOptions.czech.label"),
  };
  const options: LanguageOption[] = [
    defaultOption,
    {
      value: "sk",
      label: t("element.prompt.rewriteSelect.languageOptions.slovak.label"),
    },
    {
      value: "en",
      label: t("element.prompt.rewriteSelect.languageOptions.english.label"),
    },
    {
      value: "hu",
      label: t("element.prompt.rewriteSelect.languageOptions.hungarian.label"),
    },
  ];
  const selectedOption =
    options.find((option) => option.value === value) || defaultOption;
  return (
    <PrettySelect
      inputId={inputId}
      value={selectedOption}
      options={options}
      onChange={(option) => {
        onChange(getOptionValue<Language>(option));
      }}
      isDisabled={disabled}
    />
  );
}

export function IconOption(props: any) {
  const { Option } = components;
  const nestedCount = (props.data.label.match(/\|/g) || []).length;
  const parts = props.data.label.split("|");
  const name = parts[parts.length - 1].trim();

  let icon = <Icon name="elements" styles={{ opacity: "0.45" }} />;
  if (nestedCount) {
    icon = (
      <Icon
        name="chevron-right"
        className="small"
        styles={{ opacity: "0.45" }}
      />
    );
  }
  return (
    <Option {...props}>
      {"\u00a0\u00a0\u00a0\u00a0\u00a0".repeat(nestedCount)}
      {icon}&nbsp;&nbsp;&nbsp;{name}
    </Option>
  );
}

export function ElementsSelect({
  inputId,
  value,
  onChange,
  elements,
  loading,
  isDisabled,
}: ElementSelectProps) {
  const options = getElementsOptions(elements);
  const selectedOption = options.find((option) => option.value === value);
  return (
    <PrettySelect
      inputId={inputId}
      value={selectedOption}
      options={options}
      isLoading={loading}
      isDisabled={isDisabled}
      onChange={(option) => {
        onChange(getOptionValue<string>(option));
      }}
      components={{ Option: IconOption }}
    />
  );
}

export function DataSourceSelect({
  sources,
  value,
  onChange,
  loading,
  className,
}: DataSourceSelectProps) {
  const { t } = useTranslation();
  const options = credentialsAll.map((type) => {
    const isDisabled = !sources.includes(type);
    return {
      isDisabled,
      value: getInputTypeForSourceType(type),
      label: getPrettySourceName(type),
    };
  });
  const selectedValue = options.find((option) => option.value === value);
  return (
    <div className={`input-group ${className}`}>
      <label htmlFor="element-input-type">
        {t("element.select.source.label")}
      </label>
      <PrettySelect
        inputId="element-input-type"
        value={selectedValue}
        onChange={(option) => {
          onChange(getOptionValue<InputConversion["type"]>(option));
        }}
        isLoading={loading}
        options={options}
      />
    </div>
  );
}

export function QuerySelect({
  queries,
  value,
  onChange,
  loading,
  disabled,
}: QuerySelectProps) {
  const { t } = useTranslation();
  let defaultOption;
  const options = queries.map(({ id, name, product_count }) => {
    if (name === "♥ALLPRODUCTS♥") {
      defaultOption = {
        value: null,
        label: t("element.query.allProducts.label"),
      };
      return defaultOption;
    } else {
      return {
        value: id,
        label: `${name}\u00a0\u00a0\u00a0[${t("element.query.products.label", {
          count: product_count,
        })}]`,
      };
    }
  });
  const selectedOption =
    options.find((option) => option.value === value?.toString()) ||
    defaultOption;

  return (
    <div className="input-group">
      <label htmlFor="query-id">{t("element.query.label")}</label>
      <PrettySelect
        inputId="query-id"
        value={selectedOption}
        options={options}
        onChange={(option) => {
          onChange(Number(option?.value) || null);
        }}
        isLoading={loading}
        isDisabled={disabled}
      />
    </div>
  );
}

export function OpenAiQuestionArea({
  question,
  onChange,
  onChangeOutputType,
  elements,
}: OpenAiQuestionAreaProps) {
  const prompt = new QuestionHelper(question);

  const onChangeArgs = ({ ...fields }: Partial<Question>) => {
    // @ts-expect-error
    prompt.question = { ...question, ...fields };
    onChange(prompt.question);
  };
  const onChangeTemperature = (event: React.ChangeEvent<HTMLInputElement>) => {
    prompt.question.temperature = Number(event.target.value);
    onChange(prompt.question);
  };
  const onChangeKind = (kind: QuestionKind | null) => {
    if (kind) {
      prompt.question = QuestionHelper.getDefaultArgs(kind);
      if (prompt.isExcel()) {
        onChangeOutputType("GPT35_INSTRUCT_ANSWER");
      }
      onChange(prompt.question);
    }
  };

  return (
    <div className="prompt-group">
      <QuestionKindSelect kind={question.kind} onChange={onChangeKind} />
      {prompt.getAreaForKind(onChangeArgs, elements)}
      {prompt.getSliderForKind(onChangeTemperature)}
    </div>
  );
}

export function InputElementSelect({
  elements,
  value,
  inputType,
  onChange,
  onChangeOutputType,
  loading,
  className,
  disabled,
}: InputElementSelectProps) {
  const { t } = useTranslation();

  switch (inputType) {
    case "OPEN_AI_QUESTION":
      return (
        <div className={`input-group ${className}`}>
          <OpenAiQuestionArea
            question={
              (value === null
                ? QuestionHelper.getDefaultArgs("manual")
                : value) as Question
            }
            onChange={onChange}
            onChangeOutputType={onChangeOutputType}
            elements={elements}
          />
        </div>
      );
    case "ICE_CAT_REQUEST":
      return (
        <div className={`input-group ${className}`}>
          <ProductInfoArea
            request={
              (value === null
                ? getDefaultIceCatRequest()
                : value) as IceCatRequest
            }
            onChange={onChange}
            elements={elements}
            loading={loading}
          />
        </div>
      );
    case "GOOGLE_SEARCH_ANALYTICS":
      return (
        <div className={`input-group ${className}`}>
          <GoogleSearchArea
            request={
              (value === null
                ? getDefaultGoogleSearchRequest()
                : value) as GoogleSearchRequest
            }
            onChange={onChange}
            elements={elements}
            loading={loading}
          />
        </div>
      );
    default:
      return (
        <div className={`input-group ${className}`}>
          <label htmlFor="element-input-args">
            {t("element.select.input.label")}
          </label>
          <ElementsSelect
            inputId="element-input-args"
            elements={elements}
            value={value?.toString() || null}
            onChange={(value) => {
              onChange(value as InputConversion["args"]);
            }}
            loading={loading}
            isDisabled={disabled}
          />
        </div>
      );
  }
}

export function DataValueSelect({
  source,
  value,
  onChange,
  disabled,
  className,
  outputTypes,
}: DataValueSelectProps) {
  const { t } = useTranslation();
  const options = (outputTypes || getOutputTypesForInputType(source)).map(
    (type) => {
      return { value: type, label: getDataColumnValue(type) };
    },
  );
  const selectedOption = options.find((option) => option.value === value);

  return (
    <div className={`input-group ${className}`}>
      <label htmlFor="element-output-type">
        {t("element.select.source.value.label")}
      </label>
      <PrettySelect
        inputId="element-output-type"
        value={selectedOption}
        options={options}
        isDisabled={disabled}
        onChange={(option) => {
          onChange(getOptionValue<OutputConversion["type"]>(option));
        }}
      />
    </div>
  );
}

class OutputElementSelectComponent extends Component<
  OutputElementSelectProps,
  OutputElementSelectState
> {
  constructor(props: OutputElementSelectProps) {
    super(props);
    this.state = {
      useDefault: props.value === props.defaultValue,
    };
  }

  onChangeRadio(useDefault: boolean) {
    this.setState({ useDefault });
    if (useDefault) {
      this.props.onChange(this.props.defaultValue);
    } else {
      if (this.props.value === this.props.defaultValue) {
        this.props.onChange(null);
      } else {
        this.props.onChange(this.props.value);
      }
    }
  }

  onChangeSelect(value: string | null) {
    this.props.onChange(value);
  }

  render() {
    const { t } = this.props;
    return (
      <div className={`input-group ${this.props.className}`}>
        <p>
          <label>{t("element.select.output.label")}</label>
        </p>
        <div className="radio">
          <input
            type="radio"
            name="radio"
            id="element-output-args-default"
            checked={this.state.useDefault}
            onChange={() => {
              this.onChangeRadio(true);
            }}
            disabled={this.props.disabled}
          />{" "}
          <label htmlFor="element-output-args-default" className="radio-label">
            {t("element.select.createElement.label") + " "}
            {this.props.defaultValue === null ? "" : this.props.defaultValue}
          </label>
        </div>
        <div>
          <div className="radio">
            <input
              type="radio"
              name="radio"
              id="element-output-args-custom"
              checked={!this.state.useDefault}
              onChange={() => {
                this.onChangeRadio(false);
              }}
              disabled={this.props.disabled}
            />{" "}
            <label htmlFor="element-output-args-custom" className="radio-label">
              {t("element.select.useElement.label")}
            </label>
          </div>
          <ElementsSelect
            inputId="element-input-args-custom-select"
            elements={this.props.elements}
            value={this.state.useDefault ? null : this.props.value}
            onChange={this.onChangeSelect.bind(this)}
            loading={this.props.loading}
            isDisabled={this.state.useDefault || this.props.disabled}
          />
        </div>
      </div>
    );
  }
}

export const OutputElementSelect = withTranslation()(
  OutputElementSelectComponent,
);
