import React, { Component, useContext, useState } from "react";
import {
  Context,
  type ContextProviderType,
  type Credentials,
  type ElementMapping,
  type ElementsMappings,
} from "./ContextProvider";
import { Icon } from "../helpers/Icons";
import {
  ErrorCodeMessage,
  ErrorMessage,
  InfoMessage,
  SuccessMessage,
  WarningCodeMessage,
  WarningMessage,
} from "../helpers/Messages";
import { Block } from "../helpers/Blocks";
import {
  Tile,
  TileItems,
  TileText as SectionParagraph,
} from "../helpers/Sections";
import {
  ButtonPlain,
  ButtonPrimary,
  ButtonSecondary,
  ButtonSecondaryDanger,
  ButtonText,
} from "../helpers/Buttons";
import { useTranslation, withTranslation } from "react-i18next";
import { openWindow } from "../helpers/Mergado";
import { CustomerIdSelect } from "./Google";

export const credentialsAll = [
  "openai",
  "sklik",
  "icecat",
  "google",
  "google-ads",
];
export const credentialsInputTypeMapping: Record<string, string> = {
  sklik: "SKLIK_KEYWORD",
  openai: "OPEN_AI_QUESTION",
  icecat: "ICE_CAT_REQUEST",
  google: "GOOGLE_SEARCH_ANALYTICS",
  "google-ads": "GOOGLE_ADS",
};

export const inputOutputTypeMapping: Record<string, string[]> = {
  SKLIK_KEYWORD: ["SKLIK_AVG_CPC", "SKLIK_AVG_SEARCH_COUNT"],
  OPEN_AI_QUESTION: [
    "GPT35_ANSWER",
    "GPT35_INSTRUCT_ANSWER",
    "GPT40_ANSWER",
    "GPT4O_ANSWER",
    "DAVINCI002_ANSWER",
    "BABBAGE002_ANSWER",
  ],
  ICE_CAT_REQUEST: ["ICE_CAT_RESPONSE"],
  GOOGLE_SEARCH_ANALYTICS: [
    "GOOGLE_SEARCH_CLICKS",
    "GOOGLE_SEARCH_IMPRESSIONS",
    "GOOGLE_SEARCH_CTR",
    "GOOGLE_SEARCH_POSITION",
    "GOOGLE_SEARCH_TOP_URL",
  ],
  GOOGLE_ADS: [
    "GOOGLE_ADS_CLICKS",
    "GOOGLE_ADS_COST",
    "GOOGLE_ADS_CONVERSIONS",
    "GOOGLE_ADS_REVENUE",
  ],
};

export const inputTypePrettyMapping: Record<string, string> = {
  SKLIK_KEYWORD: "Sklik.cz",
  OPEN_AI_QUESTION: "OpenAI.com",
  ICE_CAT_REQUEST: "IceCat.com",
  GOOGLE_SEARCH_ANALYTICS: "Google Search Analytics",
  GOOGLE_ADS: "Google Ads (BETA)",
};

export const elementOutputTypeToCamelCase: Record<string, string> = {
  SKLIK_AVG_CPC: "avgCpc",
  SKLIK_AVG_SEARCH_COUNT: "avgSearchCount",
  GPT35_ANSWER: "gpt-3.5-turbo",
  GPT35_INSTRUCT_ANSWER: "gpt-3.5-turbo-instruct",
  GPT40_ANSWER: "gpt-4",
  GPT4O_ANSWER: "gpt-4o",
  DAVINCI002_ANSWER: "davinci-002",
  BABBAGE002_ANSWER: "babbage-002",
  ICE_CAT_RESPONSE: "iceCat",
  GOOGLE_SEARCH_CLICKS: "clicks",
  GOOGLE_SEARCH_IMPRESSIONS: "impressions",
  GOOGLE_SEARCH_CTR: "ctr",
  GOOGLE_SEARCH_POSITION: "position",
  GOOGLE_SEARCH_TOP_URL: "topPageUrl",
  GOOGLE_ADS_CLICKS: "clicks",
  GOOGLE_ADS_COST: "cost",
  GOOGLE_ADS_CONVERSIONS: "conversions",
  GOOGLE_ADS_REVENUE: "revenue",
};

export function getOutputTypesForInputType(inputType: string | null) {
  return inputOutputTypeMapping[inputType || "SKLIK_KEYWORD"];
}

export function getInputTypeForSourceType(source: string) {
  return credentialsInputTypeMapping[source];
}

export function getSourceTypeForInputType(inputType: string) {
  return (
    Object.keys(credentialsInputTypeMapping).find(
      (key) => credentialsInputTypeMapping[key] === inputType,
    ) || inputType
  );
}

export function getPrettySourceName(source: string) {
  return (
    inputTypePrettyMapping[credentialsInputTypeMapping[source] || source] ||
    source
  );
}

export function getDataColumnValue(elementOutputType: string) {
  return elementOutputTypeToCamelCase[elementOutputType] || elementOutputType;
}

export function getDataSourceColumnValue(elementInputType: string) {
  const styles = {
    marginRight: "5px",
  };
  switch (elementInputType) {
    case "SKLIK_KEYWORD":
      return (
        <span>
          <Icon name="service-sklik" styles={styles} /> Sklik.cz
        </span>
      );
    case "OPEN_AI_QUESTION":
      return (
        <span>
          <Icon name="service-openai" styles={styles} /> OpenAI.com
        </span>
      );
    case "GOOGLE_SEARCH_ANALYTICS":
      return (
        <span>
          <Icon name="service-google" styles={styles} /> Google Search
        </span>
      );
    case "GOOGLE_ADS":
      return (
        <span>
          <Icon name="service-adwords" styles={styles} /> Google Ads
        </span>
      );
    case "ICE_CAT_REQUEST":
      return (
        <span>
          <Icon name="product" styles={styles} /> IceCat.com
        </span>
      );
  }
}

interface DataSourceProps {
  type: string;
  token: string;
  canDelete: boolean;
  openDataSource: (type: string | null) => void;
  disabled?: boolean;
  error?: string | null;
  processingErrors: string[];
}

interface DataSourceWithStateProps extends DataSourceProps {
  setEditSource: (edit: boolean) => void;
}

function DataSourceEdit({
  type,
  token,
  disabled,
  error,
  processingErrors,
  canDelete,
  setEditSource,
}: DataSourceWithStateProps) {
  const context = React.useContext<ContextProviderType>(Context);
  const { t } = useTranslation();

  const [saveError, setSaveError] = useState<string | null>(null);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [tokenValue, setTokenValue] = useState(token);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTokenValue(event.target.value);
  };

  const handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.select();
  };

  const handleBack = () => {
    context.dismissErrors();
    setLoadingSave(false);
    setLoadingDelete(false);
    setEditSource(false);
    setSaveError(null);
  };

  const handleSave = () => {
    if (context.projectId === null) {
      return;
    }

    if (type === "google-ads" && tokenValue.endsWith("|")) {
      setSaveError(t("connection.customerIdMissing.label"));
      return;
    }

    setLoadingSave(true);
    context.setCredentials(
      {
        project_id: Number(context.projectId),
        token: tokenValue,
        type,
      },
      () => {
        handleBack();
      },
      () => {
        setLoadingSave(false);
      },
    );
  };

  const handleDelete = () => {
    if (context.projectId === null) {
      return;
    }
    setLoadingDelete(true);
    context.deleteCredentials(
      {
        project_id: Number(context.projectId),
        token: tokenValue,
        type,
      },
      () => {
        setEditSource(false);
      },
      () => {
        setLoadingSave(false);
      },
    );
  };

  let headerText;
  let bodyText;

  let fieldsSection;
  let connectionTokenDescription = t("connection.token.description");

  switch (type) {
    case "icecat":
      connectionTokenDescription = "";
      fieldsSection = (
        <div className="input-prefix-wrapper">
          <div className="input-prefix">{t("connection.username.label")}</div>
          <input
            id="input-username"
            type="text"
            value={tokenValue}
            onChange={handleOnChange}
            onFocus={handleFocus}
          />
        </div>
      );
      break;
    case "google":
      connectionTokenDescription = "";
      fieldsSection = (
        <div className="input-prefix-wrapper">
          <div className="input-prefix">{t("connection.email.label")}</div>
          <input
            id="input-email"
            type="text"
            value={tokenValue}
            disabled={true}
            onFocus={handleFocus}
          />
        </div>
      );
      break;
    case "google-ads": {
      connectionTokenDescription = "";
      const email = tokenValue.split("|")[0];
      const handleSelectOnChange = (value: string | null) => {
        setTokenValue(`${email}|${value || ""}`);
      };

      fieldsSection = (
        <div style={{ textAlign: "left" }}>
          <div className="input-group">
            <label htmlFor="input-email">{t("connection.email.label")}</label>
            <input
              id="input-email"
              type="text"
              value={email}
              disabled={true}
              onFocus={handleFocus}
            />
          </div>
          <div className={`input-group ${saveError ? "invalid" : ""}`}>
            <label htmlFor="select-customer-id">Customer ID</label>
            <CustomerIdSelect
              inputId="select-customer-id"
              value={tokenValue}
              onChange={handleSelectOnChange}
            />
          </div>
        </div>
      );
      break;
    }
    default:
      fieldsSection = (
        <div className="input-prefix-wrapper">
          <div className="input-prefix">{t("connection.token.label")}</div>
          <input
            id="input-token"
            type="text"
            value={tokenValue}
            onChange={handleOnChange}
            onFocus={handleFocus}
          />
        </div>
      );
      break;
  }

  if (disabled) {
    headerText = (
      <h3 className="link-name">
        {t("connection.create.header", {
          serviceName: getPrettySourceName(type),
        })}
      </h3>
    );
    bodyText = (
      <SectionParagraph>
        {t("connection.create.description", {
          serviceName: getPrettySourceName(type),
        })}
        <br />
        {connectionTokenDescription}
      </SectionParagraph>
    );
  } else {
    headerText = (
      <h3 className="link-name">
        {t("connection.update.header", {
          serviceName: getPrettySourceName(type),
        })}
      </h3>
    );
    bodyText = (
      <SectionParagraph>
        {t("connection.update.description", {
          serviceName: getPrettySourceName(type),
        })}
        <br />
        {connectionTokenDescription}
      </SectionParagraph>
    );
  }

  const errorMessages = [];
  if (error) {
    errorMessages.push(<ErrorCodeMessage key={error} code={error} />);
  }
  if (saveError) {
    errorMessages.push(<ErrorMessage key={error} text={saveError} />);
  }
  if (processingErrors.length > 0) {
    processingErrors.forEach((error) => {
      errorMessages.push(<WarningCodeMessage key={error} code={error} />);
    });
  }

  return (
    <Tile type={type} wide={true} header={headerText}>
      <div className="vertical-form">
        {bodyText}
        {errorMessages}
        {fieldsSection}
      </div>
      <div className="submit-area">
        <div className="buttons-row">
          <div
            title={
              canDelete ? "" : String(t("connection.delete.notAllowedLabel"))
            }
          >
            <ButtonSecondaryDanger onClick={handleDelete} disabled={!canDelete}>
              <Icon name={loadingDelete ? "loading" : "close"} />
              <span>{t("connection.delete.label")}</span>
            </ButtonSecondaryDanger>
          </div>
          <ButtonPrimary
            onClick={handleSave}
            disabled={tokenValue.length < 1 || tokenValue.includes("●")}
          >
            <Icon name={loadingSave ? "loading" : "check"} />
            <span>{t("connection.save.label")}</span>
          </ButtonPrimary>
        </div>
        <ButtonText onClick={handleBack}>
          <Icon name="arrow-left" />
          <span>{t("connection.back.label")}</span>
        </ButtonText>
      </div>
    </Tile>
  );
}

function DataSourceDisabled({ type, setEditSource }: DataSourceWithStateProps) {
  const context = useContext(Context);
  const [loadingGoogle, setLoadingGoogle] = useState(false);

  const { t } = useTranslation();
  const { elementsMappings } = React.useContext<ContextProviderType>(Context);

  let text = t("connection.inactive.label");
  const hasInactiveElements =
    elementsMappings.data.filter(
      (mapping) => mapping.input.type === getInputTypeForSourceType(type),
    ).length > 0;

  if (hasInactiveElements) {
    text = t("connection.inactiveWithElements.label");
  }

  let button;
  if (type.startsWith("google")) {
    button = (
      <ButtonSecondary
        onClick={() => {
          setLoadingGoogle(true);
          if (type === "google") {
            context.getGoogleConsoleAuthUrl(
              (url) => {
                openWindow(url);
              },
              () => {
                setLoadingGoogle(false);
              },
            );
          } else if (type === "google-ads") {
            context.getGoogleAdsAuthUrl(
              (url) => {
                openWindow(url);
              },
              () => {
                setLoadingGoogle(false);
                setEditSource(true);
              },
            );
          }
        }}
      >
        <Icon name={loadingGoogle ? "loading" : "service-google"} />
        <span>{t("connection.signInWithGoogle.label")}</span>
      </ButtonSecondary>
    );
  } else {
    button = (
      <ButtonPrimary
        onClick={() => {
          setEditSource(true);
        }}
      >
        <Icon name="plus" />
        <span>{t("connection.setUp.label")}</span>
      </ButtonPrimary>
    );
  }

  return (
    <Tile type={type} disabled={true}>
      <InfoMessage text={text} />
      {button}
    </Tile>
  );
}

function DataSourceEnabled({
  type,
  setEditSource,
  error,
  processingErrors,
}: DataSourceWithStateProps) {
  const { t } = useTranslation();
  let message = <SuccessMessage text={t("connection.active.label")} />;

  if (error) {
    message = <ErrorMessage text={t("connection.error.label")} />;
  } else if (processingErrors.length > 0) {
    const text = (
      <span>
        {t("connection.processingErrors.label")}
        <ButtonPlain
          onClick={() => {
            setEditSource(true);
          }}
        >
          {t("connection.processingErrors.button.label")}
        </ButtonPlain>
      </span>
    );
    message = <WarningMessage text={text} />;
  }

  return (
    <Tile type={type}>
      {message}
      <ButtonPrimary
        onClick={() => {
          setEditSource(true);
        }}
      >
        <Icon name="edit" />
        <span>{t("connection.update.label")}</span>
      </ButtonPrimary>
    </Tile>
  );
}

function DataSource(props: DataSourceProps) {
  const [editSource, setEditSource] = useState<boolean>(false);
  const { type, openDataSource } = props;
  const setEditSourceAndHideOthers = (value: boolean) => {
    if (value) {
      openDataSource(type);
    } else {
      openDataSource(null);
    }
    setEditSource(value);
  };

  let Element;

  if (editSource) {
    Element = DataSourceEdit;
  } else if (props.disabled) {
    Element = DataSourceDisabled;
  } else {
    Element = DataSourceEnabled;
  }
  return <Element {...props} setEditSource={setEditSourceAndHideOthers} />;
}

interface DataSourcesProps {
  credentials: Credentials[];
  mappings: ElementsMappings;
  error: string | null;
}

interface DataSourcesState {
  openedDataSource: string | null;
}

class DataSources extends Component<DataSourcesProps, DataSourcesState> {
  constructor(props: DataSourcesProps) {
    super(props);
    this.state = {
      openedDataSource: null,
    };
  }

  canDeleteCredentials(type: string): boolean {
    return !this.props.mappings.data.some((mapping: ElementMapping) => {
      return (
        mapping.is_active &&
        mapping.input.type === getInputTypeForSourceType(type)
      );
    });
  }

  render() {
    const { credentials, error } = this.props;

    const openDataSource = (type: string | null) => {
      this.setState({ openedDataSource: type });
    };
    const dataSources: Record<string, DataSourceProps> = {};
    const typesWithHyphen: string[] = credentialsAll.filter((value) =>
      value.includes("-"),
    );

    credentials.forEach(({ type, token }) => {
      dataSources[type] = {
        type,
        token,
        disabled: false,
        canDelete: this.canDeleteCredentials(type),
        openDataSource,
        processingErrors: this.props.mappings.errors.filter((name) => {
          if (
            !typesWithHyphen.includes(type) &&
            typesWithHyphen.filter((typeWithHyphen) =>
              name.startsWith(typeWithHyphen.replace("-", "_")),
            ).length > 0
          ) {
            return false;
          }
          return name.startsWith(type.split("-")[0]);
        }),
      };
    });

    credentialsAll.forEach((type) => {
      if (!dataSources[type]) {
        dataSources[type] = {
          type,
          token: "",
          disabled: true,
          canDelete: false,
          openDataSource,
          processingErrors: [],
        };
      }
    });

    let dataSourcesToShow;
    if (this.state.openedDataSource !== null) {
      dataSourcesToShow = [dataSources[this.state.openedDataSource]];
    } else {
      dataSourcesToShow = Object.values(dataSources).sort((a, b) =>
        a.type.localeCompare(b.type),
      );
    }

    return (
      <TileItems>
        {dataSourcesToShow.map((props) => (
          <DataSource {...props} key={props.type} error={error} />
        ))}
      </TileItems>
    );
  }
}

interface DataSourcesPageProps {
  t: (text: string) => string;
}

export class DataSourcesPageComponent extends Component<DataSourcesPageProps> {
  static contextType = Context;
  context!: React.ContextType<typeof Context>;

  componentDidMount() {
    if (!this.context.credentialsLoaded) {
      this.context.loadCredentials();
    }
    if (!this.context.elementsMappingsLoaded) {
      this.context.loadElementsMappings();
    }

    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        this.context.loadCredentials();
        this.context.loadElementsMappings();
      }
    });
  }

  render() {
    const { t } = this.props;

    if (!this.context.credentialsLoaded) {
      return <InfoMessage text={t("info.connections.loading.text")} />;
    }

    switch (this.context.credentialsError) {
      case "missing_token":
      case "missing_project_id":
      case "api_fetch_error":
        return <ErrorCodeMessage code={this.context.credentialsError} />;
      default:
        return (
          <Block title={t("header.navigation.connections.label")}>
            <DataSources
              credentials={this.context.credentials}
              mappings={this.context.elementsMappings}
              error={
                this.context.credentialsError || this.context.elementsError
              }
            />
          </Block>
        );
    }
  }
}

export const DataSourcesPage = withTranslation()(DataSourcesPageComponent);
